import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import AvatarIcon from '../../icons/AvatarIcon';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import PinIcon from './PinIcon/PinIcon';
import ScreenShareIcon from '../../icons/ScreenShareIcon';
import Typography from '@material-ui/core/Typography';

import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import { useAppState } from '../../state';
import Button from '@material-ui/core/Button';
import * as io from 'socket.io-client';
import useVideoContext from './../../hooks/useVideoContext/useVideoContext';
import Traducciones from './../Traducciones/Traducciones';

//const URLSocket = 'https://inspeccionremotabackend.azurewebsites.net/';
//const URLSocket = 'http://localhost:8082/';
//let socketRef = io.connect(URLSocket);

let currentURL = window.location.href;
let urlroom = '';
let urlArray = [];
console.log('currentURL');
console.log(currentURL);
let solicitoUbicacion = true;
let esAjustador = false;
if (currentURL.includes('usuario')) {
  solicitoUbicacion = false;
  esAjustador = true;
  urlArray = currentURL.split('/');
  urlroom = urlArray[urlArray.length - 3];
  console.log('urlroom');
  console.log(urlroom);
} else {
  //entonces la url del room esta al final
  urlArray = currentURL.split('/');
  urlroom = urlArray[urlArray.length - 1];
}
let linkubicacion = false;
let envieUbicacion = false;
if (solicitoUbicacion) {
  try {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        localStorage.setItem('latitude', String(position.coords.latitude));
        localStorage.setItem('longitude', String(position.coords.longitude));
        localStorage.setItem('altitude', String(position.coords.altitude ? position.coords.altitude : 0));
        //latitude = position.coords.latitude;
        /* Object.defineProperty(participant, 'latitude', {
          value: position.coords.latitude,
          writable: true,
          enumerable: true,
          configurable: true,
        });
        Object.defineProperty(participant, 'longitude', {
          value: position.coords.longitude,
          writable: true,
          enumerable: true,
          configurable: true,
        });
        Object.defineProperty(participant, 'altitude', {
          value: position.coords.altitude ? position.coords.altitude : 0,
          writable: true,
          enumerable: true,
          configurable: true,
        });
        alert(
          position.coords.latitude +
            ',' +
            position.coords.longitude +
            ',' +
            (position.coords.altitude ? position.coords.altitude : 0)
        ); */
        /* setlatitude(position.coords.latitude);
        setlongitud(position.coords.longitude);
        setaltitude(position.coords.altitude ? position.coords.altitude : 0); */
      });
    }
  } catch (error) {
    console.log('Error al obtener localización');
    console.log(error);
  }
}
const borderWidth = 2;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      isolation: 'isolate',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: 0,
      overflow: 'hidden',
      marginBottom: '0.5em',
      '& video': {
        objectFit: 'contain !important',
      },
      borderRadius: '4px',
      border: `${theme.participantBorderWidth}px solid rgb(245, 248, 255)`,
      paddingTop: `calc(${(9 / 16) * 100}% - ${theme.participantBorderWidth}px)`,
      background: 'black',
      [theme.breakpoints.down('sm')]: {
        height: theme.sidebarMobileHeight,
        width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        marginRight: '8px',
        marginBottom: '0',
        fontSize: '12px',
        paddingTop: `${theme.sidebarMobileHeight - 2}px`,
      },
    },
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      background: 'transparent',
      top: 0,
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'black',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        '& svg': {
          transform: 'scale(0.7)',
        },
      },
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1,
    },
    screenShareIconContainer: {
      background: 'rgba(0, 0, 0, 0.5)',
      padding: '0.18em 0.3em',
      marginRight: '0.3em',
      display: 'flex',
      '& path': {
        fill: 'white',
      },
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '0.18em 0.3em 0.18em 0',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    infoRowBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    typography: {
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    hideParticipant: {
      display: 'none',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    galleryView: {
      border: `${theme.participantBorderWidth}px solid ${theme.galleryViewBackgroundColor}`,
      borderRadius: '8px',
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        width: '100%',
        height: '100%',
        padding: '0',
        fontSize: '12px',
        margin: '0',
        '& video': {
          objectFit: 'cover !important',
        },
      },
    },
    dominantSpeaker: {
      border: `solid ${borderWidth}px #7BEAA5`,
    },
    botonesFlotantes: {
      border: 'solid 1px white',
      color: 'white',
      margin: '0 1vh 0 1vh',
    },
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
  isDominantSpeaker,
}: ParticipantInfoProps) {
  if (isLocalParticipant && envieUbicacion == false) {
    if (solicitoUbicacion) {
      let latitude = localStorage.getItem('latitude');
      let longitude = localStorage.getItem('longitude');
      let altitude = localStorage.getItem('altitude');
      var formdata = new FormData();
      formdata.append('sid', participant.sid);
      formdata.append('urlroom', urlroom);
      formdata.append('latitude', latitude);
      formdata.append('longitude', longitude);
      formdata.append('altitude', altitude);
      var requestOptions = {
        method: 'POST',
        body: formdata,
      };
      const urlguarda =
        process.env.API_URL ?? 'https://inspeccion-remota-api.charlestaylor.com.mx/api/agregar-usuario-llamada';
      //const urlguarda = process.env.API_URL ?? 'http://localhost/ajuste-remoto-laravel/public/api/agregar-usuario-llamada';
      fetch(urlguarda, {
        method: 'POST',
        body: formdata,
      })
        .then(response => response.json())
        .then(data => {
          envieUbicacion = true;
          console.log(data);
        })
        .catch(error => console.log('error', error));
    }
    /* console.log('participant');
    console.log(participant);
    console.log('participant.identity');
    console.log(participant.identity);
    console.log('urlroom', urlroom);
    console.log('isLocalParticipant', isLocalParticipant); */

    /* var objectoinformacionInspeccion = {
      sid: participant.sid,
      urlroom: urlroom,
      latitude: latitude,
      longitude: longitude,
      altitude: altitude,
    }; */
    //socketRef.emit('join room', objectoinformacionInspeccion);
  }
  /* socketRef.on('localizacionencontrada', function(objetolocalizacionencontrada) {
    console.log('objetolocalizacionencontrada');
    console.log(objetolocalizacionencontrada);
    if (linkubicacion == true) {
      window.open(
        'http://maps.google.com/?q=' +
          objetolocalizacionencontrada.latitude +
          ',' +
          objetolocalizacionencontrada.longitude,
        '_blank'
      );
      linkubicacion = false;
    }
  }); */

  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => !p.trackName.includes('screen') && p.kind === 'video');

  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const { isGalleryViewActive } = useAppState();

  const classes = useStyles();

  let longitudGlobal = 0;
  let latitudGlobal = 0;
  let altitudGlobal = 0;
  const { room } = useVideoContext();

  if (esAjustador && !isLocalParticipant) {
    return (
      <div
        className={clsx(classes.container, {
          [classes.hideParticipant]: hideParticipant,
          [classes.cursorPointer]: Boolean(onClick),
          [classes.dominantSpeaker]: isDominantSpeaker,
          [classes.galleryView]: isGalleryViewActive,
        })}
        onClick={onClick}
        data-cy-participant={participant.identity}
      >
        <div className={classes.infoContainer}>
          <NetworkQualityLevel participant={participant} />
          <div className={classes.infoRowBottom}>
            {isScreenShareEnabled && (
              <span className={classes.screenShareIconContainer}>
                <ScreenShareIcon />
              </span>
            )}
            <span className={classes.identity}>
              <AudioLevelIndicator audioTrack={audioTrack} />
              <Typography variant="body1" className={classes.typography} component="span">
                {participant.identity}
                {/* {isLocalParticipant && ' (You)'} */}
              </Typography>
              <Button
                className={classes.botonesFlotantes}
                onClick={() => {
                  /* var objectoinformacionInspeccion = {
                    sid: participant.sid,
                    urlroom: urlroom,
                  };
                  console.log('la localizacion de');
                  console.log(participant.identity);
                  socketRef.emit('localizacion', objectoinformacionInspeccion); */
                  var formdata = new FormData();
                  formdata.append('sid', participant.sid);
                  formdata.append('urlroom', urlroom);
                  const urlguarda =
                    process.env.API_URL ??
                    'https://inspeccion-remota-api.charlestaylor.com.mx/api/consulta-usuario-llamada';
                  //const urlguarda = process.env.API_URL ?? 'http://localhost/ajuste-remoto-laravel/public/api/consulta-usuario-llamada';
                  fetch(urlguarda, {
                    method: 'POST',
                    body: formdata,
                  })
                    .then(response => response.json())
                    .then(data => {
                      console.log('data');
                      console.log(data.data);
                      window.open(
                        'http://maps.google.com/?q=' + data.data.latitude + ',' + data.data.longitude,
                        '_blank'
                      );
                    });
                  //linkubicacion = true;
                }}
              >
                {Traducciones('ubicacion')}
              </Button>
              <Button
                className={classes.botonesFlotantes}
                onClick={async () => {
                  // console.log('participant');
                  // console.log(participant);
                  // console.log('participant.videoTracks');
                  // console.log(participant.videoTracks);

                  let videotrack: any = {};
                  for (let [key, value] of participant.videoTracks.entries()) {
                    videotrack = value;
                    //   console.log('taaa');
                  }
                  console.log('videotrack');
                  console.log(videotrack);
                  // console.log('videotrack.track');
                  // console.log(videotrack.track);
                  // console.log('videotrack.track.mediaStreamTrack');
                  // console.log(videotrack.track.mediaStreamTrack);
                  // console.log('videotrack.track.mediaStream');
                  // console.log(videotrack.track.mediaStream);
                  // console.log('videotrack.track.mediaStreamTrack.srcObject ');
                  // console.log(videotrack.track.mediaStreamTrack.srcObject);
                  var newStream = new MediaStream();
                  console.log('newStream');
                  console.log(newStream);
                  newStream.addTrack(videotrack.track.mediaStreamTrack);
                  console.log('newStream con el track');
                  console.log(newStream);
                  var video = document.createElement('video');
                  console.log('.....');

                  // document.body.appendChild(video);
                  video.srcObject = newStream;
                  video.play();
                  console.log();
                  //var srcObject = URL.createObjectURL(newStream);

                  var canvas = document.createElement('canvas');
                  canvas.width = 800;
                  canvas.height = 600;
                  canvas.id = 'myCanvas';
                  // document.body.appendChild(canvas);
                  setTimeout(() => {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    console.log('tomar foto seccion', video);
                    console.log(canvas.width);
                    console.log(canvas.height);
                    canvas?.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                    var img = document.createElement('img');
                    img.src = canvas.toDataURL();
                    //   document.body.appendChild(img);
                  }, 1000);
                  // var img = document.createElement('img');
                  // const frame = canvas.toDataURL('image/png');
                  // document.body.appendChild(frame);
                  // console.log("frame", frame);
                  // let blob = await fetch(frame).then(r => r.blob());

                  let clave: string | undefined = '';
                  clave = room?.name;
                  if (clave == undefined) {
                    clave = '';
                  }

                  var formdata = new FormData();
                  // let imageBlob : Blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
                  let imageBlob: any = undefined;
                  setTimeout(() => {
                    // ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    // OBTENGO LAS COORDENADAS DEL USUARIO DESEADO
                    var formdata = new FormData();
                    formdata.append('sid', participant.sid);
                    formdata.append('urlroom', urlroom);
                    const urlguarda =
                      process.env.API_URL ??
                      'https://inspeccion-remota-api.charlestaylor.com.mx/api/consulta-usuario-llamada';
                    //const urlguarda = process.env.API_URL ?? 'http://localhost/ajuste-remoto-laravel/public/api/consulta-usuario-llamada';
                    fetch(urlguarda, {
                      method: 'POST',
                      body: formdata,
                    })
                      .then(response => response.json())
                      .then(data => {
                        console.log('gps en foto');
                        console.log(data.data);
                        latitudGlobal = data.data.latitude;
                        longitudGlobal = data.data.longitude;
                        altitudGlobal = data.data.altitude;
                        console.log(latitudGlobal);
                        console.log(longitudGlobal);
                        console.log(altitudGlobal);
                        canvas.toBlob(blob => {
                          imageBlob = canvas.toDataURL('image/png');
                          console.log('imageblob');
                          console.log(imageBlob);
                          formdata.append('idinspeccion', '');
                          formdata.append('observacion_seccion', '-');
                          formdata.append('tituloSeccion', '-sin dato-');
                          formdata.append('observacion_fotografia_seccion', '-sin dato-');
                          formdata.append('latitud', latitudGlobal.toString());
                          formdata.append('longitud', longitudGlobal.toString());
                          formdata.append('altitud', altitudGlobal.toString());
                          formdata.append('idreporte_inspeccion', '');
                          formdata.append('fotografia', blob, 'archivo.png');
                          formdata.append('conreunion', 'si');
                          formdata.append('clave_inspeccion', clave);
                          var requestOptions = {
                            method: 'POST',
                            body: formdata,
                            // redirect: 'follow',
                          };
                          /* const urlguarda =
                              process.env.API_URL ??
                              'http://localhost/InspeccionRemota/Backend/public/api/carga-fotografia-reporte'; */
                          const urlguarda =
                            process.env.API_URL ??
                            'https://inspeccion-remota-api.charlestaylor.com.mx/api/carga-fotografia-reporte';
                          fetch(urlguarda, {
                            method: 'POST',
                            body: formdata,
                          })
                            .then(response => response.text())
                            .then(result => {
                              alert(Traducciones('fotoguardadaexitosamente'));
                              console.log('result carga foto ss', result);
                            })
                            .catch(error => {
                              alert('Error');
                              console.log('error', error);
                            });
                        });
                        // window.open(
                        //     'http://maps.google.com/?q=' + data.data.latitude + ',' + data.data.longitude,
                        //     '_blank'
                        // );
                      });
                  }, 1000);

                  // if(blob == null){
                  //     alert("Error");
                  //     return;
                  // }

                  //console.log("videotrack.getSettings()");
                  //console.log(videotrack.getSettings());
                }}
              >
                {Traducciones('tomarfotografia')}
              </Button>
              <Button
                className={classes.botonesFlotantes}
                onClick={() => {
                  let videotrack: any = {};
                  for (let [key, value] of participant.videoTracks.entries()) {
                    videotrack = value;
                  }
                  var newStream = new MediaStream();
                  console.log('newStream');
                  console.log(newStream);
                  newStream.addTrack(videotrack.track.mediaStreamTrack);
                  console.log('newStream con el track modificado');
                  console.log(newStream);
                  var video = document.createElement('video');
                  document.body.appendChild(video);
                  video.srcObject = newStream;

                  video.play();
                  setTimeout(() => {
                    video.requestFullscreen();
                    console.log('cambio3');
                  }, 1000);
                  video.addEventListener('fullscreenchange', function() {
                    if (document.fullscreenElement === null) {
                      console.log('Pantalla completa cerrada');
                      video.remove();
                    }
                  });
                  console.log('cambio2');
                }}
              >
                {Traducciones('expandir')}
              </Button>
            </span>
          </div>
          <div>{isSelected && <PinIcon />}</div>
        </div>
        <div className={classes.innerContainer}>
          {(!isVideoEnabled || isVideoSwitchedOff) && (
            <div className={classes.avatarContainer}>
              <AvatarIcon />
            </div>
          )}
          {isParticipantReconnecting && (
            <div className={classes.reconnectingContainer}>
              <Typography variant="body1" className={classes.typography}>
                Reconnecting...
              </Typography>
            </div>
          )}
          {children}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={clsx(classes.container, {
          [classes.hideParticipant]: hideParticipant,
          [classes.cursorPointer]: Boolean(onClick),
          [classes.dominantSpeaker]: isDominantSpeaker,
          [classes.galleryView]: isGalleryViewActive,
        })}
        onClick={onClick}
        data-cy-participant={participant.identity}
      >
        <div className={classes.infoContainer}>
          <NetworkQualityLevel participant={participant} />
          <div className={classes.infoRowBottom}>
            {isScreenShareEnabled && (
              <span className={classes.screenShareIconContainer}>
                <ScreenShareIcon />
              </span>
            )}
            <span className={classes.identity}>
              <AudioLevelIndicator audioTrack={audioTrack} />
              <Typography variant="body1" className={classes.typography} component="span">
                {participant.identity}
                {/* {isLocalParticipant && ' (You)'} */}
              </Typography>
              <Button
                className={classes.botonesFlotantes}
                onClick={() => {
                  let videotrack: any = {};
                  for (let [key, value] of participant.videoTracks.entries()) {
                    videotrack = value;
                  }
                  var newStream = new MediaStream();
                  console.log('newStream');
                  console.log(newStream);
                  newStream.addTrack(videotrack.track.mediaStreamTrack);
                  console.log('newStream con el track');
                  console.log(newStream);
                  var video = document.createElement('video');
                  document.body.appendChild(video);
                  video.srcObject = newStream;

                  video.play();
                  setTimeout(() => {
                    video.requestFullscreen();
                    console.log('cambio3');
                  }, 1000);
                  video.addEventListener('fullscreenchange', function() {
                    if (document.fullscreenElement === null) {
                      console.log('Pantalla completa cerrada');
                      video.remove();
                    }
                  });
                  console.log('cambio2');
                }}
              >
                {Traducciones('expandir')}
              </Button>
            </span>
          </div>
          <div>{isSelected && <PinIcon />}</div>
        </div>
        <div className={classes.innerContainer}>
          {(!isVideoEnabled || isVideoSwitchedOff) && (
            <div className={classes.avatarContainer}>
              <AvatarIcon />
            </div>
          )}
          {isParticipantReconnecting && (
            <div className={classes.reconnectingContainer}>
              <Typography variant="body1" className={classes.typography}>
                Reconnecting...
              </Typography>
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
}
