import { useEffect, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useIsRecording() {
  const { room } = useVideoContext();
  const [isRecording, setIsRecording] = useState(Boolean(room?.isRecording));

  useEffect(() => {
    if (room) {
      setIsRecording(room.isRecording);

      const handleRecordingStarted = async () => {
        //alert('ha iniciado');
        console.log('hola :v', room);
        setIsRecording(true);
        //paso mandao a inspeccion remota back
        //registro el room en
        //lo guardo
        //registros en video guardadovideollamadas
        //inspeccion remota front
        //agregar boton que diga revisar composiciones //
        //revisar
        //mando llamar al back de videollada en composiciones
        var formdata = new FormData();
        formdata.append('accion', 'falta-compo');
        formdata.append('room_sid', room.sid);
        formdata.append('clave_inspeccion', room.name);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow',
        };
        const urlguarda =
          process.env.API_URL ?? 'https://inspeccion-remota-api.charlestaylor.com.mx/api/guarda-videollamada';
        fetch(urlguarda, {
          method: 'POST',
          body: formdata,
        })
          .then(response => response.text())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
      };
      const handleRecordingStopped = async () => {
        /* console.log('ha terminado');
        const compo = await fetch('http://localhost:8081/composiciones', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            room_sid: room.sid,
          }),
        }).then(res => res.json());
        console.log(compo); */
        setIsRecording(false);
      };

      room.on('recordingStarted', handleRecordingStarted);
      room.on('recordingStopped', handleRecordingStopped);

      return () => {
        room.off('recordingStarted', handleRecordingStarted);
        room.off('recordingStopped', handleRecordingStopped);
      };
    }
  }, [room]);

  return isRecording;
}
